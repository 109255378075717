import TicketDetailsFirstLevel from '../views/first-level-support/TicketDetailsFirstLevel.vue'
import ChangePasswordFirstLevel from '../views/ChangePassword.vue'
import TicketsFirstLevel from '../views/first-level-support/TicketsFirstLevel.vue'
import KnowledgeBase from '../views/first-level-support/KnowledgeBase.vue'
import AddViaChannel from '../views/first-level-support/AddViaChannel.vue'
import BatchOps from '../views/first-level-support/BatchOps.vue'
import UserDetailsFirstLevel from '../views/UserDetails.vue' // for viewing user details.
import ProfileFirstLevel from '../views/Profile.vue'
import FirstLevelSupportLayout from '../layouts/FirstLevelSupport.vue'
import auth from '../helper/auth'

const firstLevelSupportRoutes = [
  {
    path: '/first-level-support',
    component: FirstLevelSupportLayout,
    beforeEnter: auth.middlewareFirstLevel,
    children: [
      {
        path: '/',
        name: 'HomeFirstLevel',
        component: TicketsFirstLevel
      },
      {
        path: 'profile',
        name: 'ProfileFirstLevel',
        component: ProfileFirstLevel
      },
      {
        path: 'tickets/:status', // status is sticket state e.g. open, closed, escalated
        name: 'TicketsFirstLevel',
        component: TicketsFirstLevel
      },
      {
        path: 'ticket/:ref',
        name: 'TicketDetailsFirstLevel',
        component: TicketDetailsFirstLevel
      },
      {
        path: 'user/:uuid',
        name: 'UserDetailsFirstLevel',
        component: UserDetailsFirstLevel
      },
      {
        path: 'change-password',
        name: 'ChangePasswordFirstLevel',
        component: ChangePasswordFirstLevel
      },

      {
        path: 'SLA-Breached',
        name: 'SLABreached_FL',
        component: () => import('../views/reports/SLABreached.vue')
      },
      {
        path: 'SLA-Compliance',
        name: 'SLACompliance_FL',
        component: () => import('../views/reports/SLACompliance.vue')
      },
      {
        path: 'Top-10-Cases',
        name: 'Top10Cases_FL',
        component: () => import('../views/reports/Top10Cases.vue')
      },
      {
        path: 'batch-ops',
        name: 'BatchOps',
        component: BatchOps
      },
      {
        path: 'knowledge-base',
        name: 'KnowledgeBase',
        component: KnowledgeBase
      },
      {
        path: 'add-via-channel',
        name: 'AddViaChannel',
        component: AddViaChannel
      }
      /* {
             path: 'create',
             name: 'CreateTicketFirstLevel',
             component: () => import('../views/first-level-support/CreateTicketFirstLevel.vue')
         },
         {
             path: 'my-tickets',
             name: 'MyTicketsFirstLevel',
             component: () => import('../views/first-level-support/MyTicketsFirstLevel.vue')
         }, */

      /* Profile */
      /* {
            path: 'profile',
            name: 'UserProfileFirstLevel',
            component: () => import('../views/Profile.vue')
        }, */
    ]
  }
]

export default firstLevelSupportRoutes
